/* eslint-disable prefer-destructuring */
import rebass from '@rebass/preset';
import base from './base';
import { lightenColor } from '../utils';

const breakpoints = ['320px', '540px', '720px', '960px', '1140px', '1400px'];

// aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];

// color definitions
const colors = {
  text: '#0C1821',
  'text-inverse': '#f9f9f9',
  background: '#FCFBFC',
  primary: '#0B4A59',
  secondary: '#CC2936',
  muted: '#334759',
};

const baseStyles = {
  ...rebass,
  ...base,
};

const { buttons, text } = baseStyles;

export default () => ({
  ...baseStyles,
  breakpoints,
  colors,
  fonts: {
    body: 'Roboto, system-ui, sans-serif',
    heading: 'Roboto, system-ui, sans-serif',
    monospace: '"Roboto Mono", monospace',
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  buttons: {
    ...buttons,
    primary: {
      ...buttons.primary,
      textTransform: 'uppercase',
      letterSpacing: '0.75px',
      fontSize: '.95rem',
      fontWeight: 400,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: lightenColor(colors.primary, 20),
      },
    },
  },
  text: {
    ...text,
    display: {
      ...text.display,
      letterSpacing: '0.5px',
    },
  },
});
