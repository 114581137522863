import React from 'react';
import PropTypes from 'prop-types';
import { Text as RText } from 'rebass/styled-components';
import styled from 'styled-components';

const Styles = {
  Text: styled(RText)`
    letter-spacing: 0.5px;
  `,
};

const Text = (props) => (
  <Styles.Text {...props} />
);

Text.propTypes = {
  variant: PropTypes.string,
};

Text.defaultProps = {
  variant: 'default',
};

export default Text;
