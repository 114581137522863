import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';

const Styles = {
  Layout: styled(Flex)(() => `
    height: 100%;
    flex-direction: column;
  `),
};

const Layout = ({ children }) => (
  <Styles.Layout>
    <Box p={[4, 4, 4, 6, 6, 6]}>
      {children}
    </Box>
  </Styles.Layout>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
