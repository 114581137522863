/* global window */
const AUTH_KEY = 'gatsby_name.ch_key';

const isBrowser = () => typeof window !== 'undefined';

const getData = () => {
  if (isBrowser() && window.localStorage.getItem(AUTH_KEY)) {
    return JSON.parse(window.localStorage.getItem(AUTH_KEY));
  }
  return {};
};

const setData = (data) => window.localStorage.setItem(AUTH_KEY, JSON.stringify(data));

export const login = (token) => {
  const data = { token };
  setData(data);
  return true;
};

export const logout = (callback) => {
  setData({});
  if (callback) {
    callback();
  }
};

export const isLoggedIn = () => {
  const data = getData();
  return !!data.token;
};
